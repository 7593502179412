import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField, Icon, Button, Drawer, Switch, InputAdornment, Paper } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconLink } from '@tabler/icons-react';
import TestConnection from '../Modal/TestConnection';
import axios from '@fuse/utils/axios';
import { Autocomplete } from '@material-ui/lab'
import { generateProperLocationString } from '@fuse/utils/general';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import AddLocationFromQuickAction from 'app/fuse-layouts/shared-components/modal/AddLocationFromQuickAction';
import { useDispatch } from 'react-redux';
import { toggleProgressBarVisibility } from '../store/dataSyncReducer';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles(theme => (
  {
    drawerWidth: {
      width: 520,
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
  }
))

function ConnectSalto({ openSaltoDrawer, handleCloseForm }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const [checkedLock, setCheckedLock] = useState(false);
  const [checkedAccess, setCheckedAccess] = useState(false);
  const [checkedLiveLock, setCheckedLiveLock] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [openTestConnectionModal, setOpenTestConnectionModal] = useState(false);
  const [testConnectionIsLoading, setTestConnectionIsLoading] = useState(false);
  const [testConnectionFailed, setTestConnectionFailed] = useState(false);
  const [locationMenu, setLocationMenu] = useState([]);
  const [ openAddLocationModal, setOpenAddLocationModal ] = useState(false)

  //form validation 
  const schema = yup.object({
    clientID: yup.string().trim().required("Client ID is required."),
    clientSecret: yup.string().trim().required("Client Secret is required."),
    siteIDArray: yup.array().of(yup.object({
      siteID: yup.string().trim().required("Site ID is required."),
      locationDetail: yup.object().required("Location is required.")
    })).required("Site ID is required."),
    userEmail: yup.string().email().trim().required("User email is required."),
    userPassword: yup.string().trim().required("User password is required.")
  })

  const defaultValues = {
    clientID: '',
    clientSecret: '',
    siteIDArray: [{ siteID: '', locationDetail: null }],
    userEmail: '',
    userPassword: ''
  }

  const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "siteIDArray",
  });

  function addAnOtherSiteId() {
    append({ siteID: '', locationDetail: null })
  }

  function handleOnClickConnectButton(formData) {
    axios.post(`/salto/connect-salto`, {
      salto_client_id: formData.clientID,
      salto_client_secret: formData.clientSecret,
      sites_details: formData.siteIDArray.map(item => {
        return {
          site_id: item.siteID,
          location_id: item.locationDetail.id,
          location_entity_external_platform_id: item.locationDetail.entity_external_platform_id
        }
      }),
      salto_user_email: formData.userEmail,
      salto_user_password: formData.userPassword,
    }).then((res) => {
      setDefault()
    }).catch((err) => {
      toast.error(err?.response?.data?.message || "Something went wrong")
      console.log(err)
    }).finally(() => {

    })
  }

  function setDefault() {
    handleCloseForm()
    reset(defaultValues)
  }

  const toggleShowPassword = () => setShowPassword((show) => !show);

  const handleCloseTestConnection = () => {
    setOpenTestConnectionModal(false)
    setTestConnectionIsLoading(false)
    setTestConnectionFailed(false)
  };

  function testApiConnection(formData) {
    setTestConnectionIsLoading(true)
    axios.post(`/salto/test-connection`, {
      salto_client_id: formData.clientID,
      salto_client_secret: formData.clientSecret,
      sites_details: formData.siteIDArray.map(item => {
        return {
          site_id: item.siteID,
          location_id: item.locationDetail.id,
          location_entity_external_platform_id: item.locationDetail.entity_external_platform_id
        }
      }),
      salto_user_email: formData.userEmail,
      salto_user_password: formData.userPassword,
    }).then((res) => {
      setTestConnectionFailed(false)
    }).catch((err) => {
      setTestConnectionFailed(true)
    }).finally(() => {
      setTestConnectionIsLoading(false)
    })
  }

  const handleOpenTestConnection = (formData) => {
    setOpenTestConnectionModal(true)
    testApiConnection(formData)
  }

  const handleOpenCloseAddLocation = () => {
    setOpenAddLocationModal(!openAddLocationModal)
  }

  const newLocationAddedEvent = (createdData) => {
    setOpenAddLocationModal(!openAddLocationModal)
    // setValue('location',{
    //     entity_external_platform_id: createdData.entity_external_platform_id,
    //     id: createdData.id,
    //     location_name: createdData.location_name,
    //     address1: createdData.address1 || '',
    //     address2: createdData.address2 || '',
    //     city: createdData.city || '',
    //     state: createdData.state || '',
    //     country: createdData.country || '',
    // })
    // clearErrors("location")
    fetchLocationSuggestions()
  }

  const fetchLocationSuggestions = (value = '') => {
    axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
      let locations = (AlphabeticalSorting(response.data.data.locations,'location_name'))
      setLocationMenu(locations)
      getSaltoAccountDetails(locations)
    }).catch(err => {
      console.log('error',err);
    })
	}

  function getSaltoAccountDetails(locations=[]){
    axios.get(`/salto/get-salto-details`).then(response => {
      if(response.data.data.details){
        const {
          salto_client_id,
          salto_client_secret,
          sites_details,
          salto_user_email,
          salto_user_password
        } = response.data.data.details;

        const editDataForSiteDetails = sites_details.map((item)=>{
          return {
            siteID: item.site_id,
            locationDetail: locations.find(i => i.id === item.location_id)
          }
        });
        reset({ 'siteIDArray': editDataForSiteDetails })
        setValue('clientID', salto_client_id);
        setValue('clientSecret', salto_client_secret);
        setValue('userEmail', salto_user_email);
        setValue('userPassword', salto_user_password);
      }
		}).catch(err => {
			console.log('error',err);
		})
  }

  useEffect(()=>{
    fetchLocationSuggestions()
  },[]);

  return (
    <>
      <Drawer anchor='right' open={openSaltoDrawer} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder bottom_border'>
        <div className='heading_holder'>
          <h4 className='heading'>Connect Salto</h4>
          <IconButton onClick={setDefault} style={{ padding: '0px' }}>
            <Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
          </IconButton>
        </div>

        <div className='cstm_wizard_holder'>
          <Box sx={{ width: '100%', paddingTop: '30px' }} className='box_holder'>
            <div className='main_content_holder'>
              <div className='content_holder'>
                <div className="text-left pb-28 border_bottom">
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Client ID
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name='clientID'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          name=""
                          variant='outlined'
                          fullWidth
                          error={!!errors.clientID}
                          helperText={errors?.clientID?.message}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px',
                              fontSize: '10px',
                            }
                          }}
                          style={{ width: '100%', backgroundColor: '#fff' }}
                        />
                      )}
                    />
                  </div>

                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Client Secret
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name="clientSecret"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          name=""
                          variant='outlined'
                          fullWidth
                          error={!!errors.clientSecret}
                          helperText={errors?.clientSecret?.message}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px',
                              fontSize: '10px',
                            }
                          }}
                          style={{ width: '100%', backgroundColor: '#fff' }}
                        />
                      )}
                    />
                  </div>

                  {
                    fields.map((item, index) => (
                      <React.Fragment key={index}>
                        <div className='mb-10'>
                          <Typography className='status_text'>
                            Site ID
                            <span className='required_span'>*</span>
                          </Typography>
                          <Controller
                            name={`siteIDArray.${index}.siteID`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                name=""
                                variant='outlined'
                                fullWidth
                                error={!!errors?.siteIDArray?.[index]?.siteID}
                                helperText={errors?.siteIDArray?.[index]?.siteID?.message}
                                FormHelperTextProps={{
                                  style: {
                                    margin: 0,
                                    backgroundColor: '#f4f4f4',
                                    width: '100%',
                                    paddingTop: '2px',
                                    fontSize: '10px',
                                  }
                                }}
                                style={{ width: '100%', backgroundColor: '#fff' }}
                              />
                            )}
                          />
                          <div className='mb-10 mt-10'>
                            <Typography className='status_text'>
                              Location
                              <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                              name={`siteIDArray.${index}.locationDetail`}
                              className="mb-16"
                              control={control}
                              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                  <Autocomplete
                                    {...field}
                                    style={{ width: '100%' }}
                                    className='main_autocomplete'
                                    options={locationMenu}
                                    onChange={(event, value) => field.onChange(value)}
                                    PaperComponent={({ children }) => (
                                      <Paper className='autocomplete_holder'>{children}
                                        <div className='p-16'>
                                          <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocation()}>
                                            <i className='ti ti-plus' />
                                            <span>Add Location</span>
                                          </Button>
                                        </div>
                                      </Paper>
                                    )}
                                    getOptionLabel={
                                      option => {
                                        return generateProperLocationString({
                                          location_name: option?.location_name ?? '',
                                          address1: option?.address1 ?? '',
                                          address2: option?.address2 ?? '',
                                          city: option?.city ?? '',
                                          state: option?.state ?? '',
                                          country: option?.country ?? ''
                                        })
                                      }
                                    }
                                    renderInput={(params) => {
                                      return (
                                        <div ref={params.InputProps.ref}>
                                          <TextField
                                            {...params}
                                            style={{ background: 'white' }}
                                            required
                                            error={!!errors?.siteIDArray?.[index]?.locationDetail}
                                            helperText={errors?.siteIDArray?.[index]?.locationDetail?.message && "Location is required"}
                                            FormHelperTextProps={{
                                              style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                              }
                                            }}
                                            variant="outlined"
                                            fullWidth
                                          />
                                        </div>
                                      )
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div className='flex justify-end pt-4'>
                            <Button
                              onClick={addAnOtherSiteId}
                              className='add_anotherButton mt-0 mr-3 p-1 capitalize'
                              startIcon={
                                <Icon fontSize="small" title="Add Another">
                                  add
                                </Icon>}>
                              Add Another
                            </Button>
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                  }

                  <div className='mb-10'>
                    <Typography className='status_text'>
                      User Email
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name="userEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          name=""
                          variant='outlined'
                          fullWidth
                          error={!!errors.userEmail}
                          helperText={errors?.userEmail?.message}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px',
                              fontSize: '10px',
                            }
                          }}
                          style={{ width: '100%', backgroundColor: '#fff' }}
                        />
                      )}
                    />
                  </div>

                  <div className='mb-10'>
                    <Typography className='status_text'>
                      User Password
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name="userPassword"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          type={showPassword ? 'text' : 'password'}
                          name="userPassword"
                          variant='outlined'
                          fullWidth
                          error={!!errors.userPassword}
                          helperText={errors?.userPassword?.message}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px',
                              fontSize: '10px',
                            }
                          }}
                          style={{ width: '100%', backgroundColor: '#fff' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment style={{ marginRight: '14px' }} className='icon_holder'>
                                <Icon
                                  style={{ opacity: '0.7', fontSize: '25px', cursor: 'pointer' }}
                                  aria-label="toggle userPassword visibility"
                                  onClick={toggleShowPassword}
                                  onMouseDown={toggleShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='test_connection'>
                    <span style={{ display: 'flex', alignItems: 'center' }} onClick={handleSubmit(handleOpenTestConnection)}>
                      <IconLink />
                      <span className='pl-4'>Test Connection</span>
                    </span>
                  </div>
                </div>

                <div className="text-left pt-28">
                  <h4 className='submain_heading'>Synchronization Settings(Optional)</h4>
                  <div className='sub_content pb-16'>Specify the data you wish to sync</div>

                  <div className='flex justify-between items-center mb-16'>
                    <label className='normal_label_holder'>Lock Status</label>
                    <Switch
                      checked={checkedLock}
                      onChange={(event) => setCheckedLock(event.target.checked)}
                      disableRipple
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          padding: '14px', // Adjust padding to align the thumb

                          '&:hover': {
                            backgroundColor: 'transparent'
                          },
                          '&.Mui-checked': {
                            color: '#66bb6a', // Thumb color when checked
                            transform: 'translateX(16px)', // Move thumb when checked
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#66bb6a', // Track color when checked
                            },
                          },
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor: '#ffffff',
                          width: 16,
                          height: 16,
                        },
                        '& .MuiSwitch-track': {
                          width: 36,
                          height: 20,
                          backgroundColor: '#EAECF0',
                          borderRadius: 16,
                          opacity: 1
                        },
                      }}
                    />
                  </div>

                  <div className='flex justify-between items-center mb-16'>
                    <label className='normal_label_holder'>Access Events</label>
                    <Switch
                      checked={checkedAccess}
                      onChange={(event) => setCheckedAccess(event.target.checked)}
                      disableRipple
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          padding: '14px', // Adjust padding to align the thumb

                          '&:hover': {
                            backgroundColor: 'transparent'
                          },
                          '&.Mui-checked': {
                            color: '#66bb6a', // Thumb color when checked
                            transform: 'translateX(16px)', // Move thumb when checked
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#66bb6a', // Track color when checked
                            },
                          },
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor: '#ffffff',
                          width: 16,
                          height: 16,
                        },
                        '& .MuiSwitch-track': {
                          width: 36,
                          height: 20,
                          backgroundColor: '#EAECF0',
                          borderRadius: 16,
                          opacity: 1
                        },
                      }}
                    />
                  </div>

                  <div className='flex justify-between items-center'>
                    <label className='normal_label_holder'>Live Lock Status</label>
                    <Switch
                      checked={checkedLiveLock}
                      onChange={(event) => setCheckedLiveLock(event.target.checked)}
                      disableRipple
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          padding: '14px', // Adjust padding to align the thumb

                          '&:hover': {
                            backgroundColor: 'transparent'
                          },
                          '&.Mui-checked': {
                            color: '#66bb6a', // Thumb color when checked
                            transform: 'translateX(16px)', // Move thumb when checked
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#66bb6a', // Track color when checked
                            },
                          },
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor: '#ffffff',
                          width: 16,
                          height: 16,
                        },
                        '& .MuiSwitch-track': {
                          width: 36,
                          height: 20,
                          backgroundColor: '#EAECF0',
                          borderRadius: 16,
                          opacity: 1
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='btn_holder'>
                <Button className='primary_btn' onClick={handleSubmit(handleOnClickConnectButton)}>Connect</Button>
              </div>
            </div>
          </Box>
        </div>
        {openTestConnectionModal &&
          <TestConnection
            open={openTestConnectionModal}
            close={handleCloseTestConnection}
            testConnectionIsLoading={testConnectionIsLoading}
            testConnectionFailed={testConnectionFailed}
            onClickReTry={handleSubmit(testApiConnection)}
          />
        }
      </Drawer>
      {
        openAddLocationModal && 
        <AddLocationFromQuickAction
          handleOpenCloseAddModalEvent={handleOpenCloseAddLocation}
          openAddStatusModalStateData={openAddLocationModal}
          newDataAddedEvent={newLocationAddedEvent}
        />
      }  
    </>
  )
}

export default ConnectSalto