import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { quickAddedTicket } from '../store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "SERVICE_REQUEST";
	const classes = useStyles();
	const router = useRouter()
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	)
	const [filterApplied, setFilterApplied] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [logs, setLogs] = useState([]);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const quick_added_ticket = useSelector(
		(state) => state.ServiceRequestsApp.reportViewDialogReducer.quickAddedTicket
	  );
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};
	const tableHeader = ["ticket_id", "serial_number", "status", "location", "subject_line", "detailed_description_of_the_issue"]

	const handleClickOpen = (data) => {
		router.push(`/service-tickets/${data.id}`)
	}

	const handleArchive = (data) => {
		setModalData(data);
	};

	function handleClose() {
		setModalData(null);
	}

	const handleAddServiceTicket = () => {
		dispatch(openFormViewerDialog());
	}

	function handleConfirm() {
		axios.delete(`/service/archived-service-request/${modalData.id}`).then(() => {
			handleClose();
			props.fetchServiceReq();
			toast.success("Service Ticket archived Successfully.");
		}).catch((err) => {
			console.log("err", err.response);
		});
	}

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function formatLogs(data) {
		let length = data.length
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				ticket_id: item.service_request ? item.service_request.ticket_id : item.ticket_id,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				asset_serial_number: item.asset_serial_number || 'N/A'
			}
			length--;
			return obj
		})
	}

	function getLogs() {
		axios.get("/service/get-all-activities").then((res) => {
			setLogs(formatLogs(res.data.data.activities));
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
		});
	}

	const onGridReady = (params) => {
		setGridApi(params);
		if (router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function getReport(event) {
		const report_name = router.query.report_name;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function loadInitialState(params) {
		axios
			.get(`/user-view/show-view-order?table_name=${table_name}`)
			.then((results) => {
				const { data } = results.data;
				if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data.filter);
					params?.columnApi.applyColumnState({ state: order });
					params?.api.setFilterModel(filter);
					if (Object.keys(filter)?.length) {
						setFilterApplied(true)
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	function cellRendererFramework( params ){
		if(params.column.colId == "serial_number"){
			if(params.value === "No Serial Number") return (<span>{params.value}</span>)
			return (<a
				style={{
					height: '10px',
					width: '10px',
					color: 'black',
					cursor: 'pointer',
					marginTop: '2px',
					borderRadius: '50%',
					display: 'inline-block',
				}}
				onClick={() => redirectToAssetProfile(params)}
				variant="subtitle1"
			>
				{<span>{params.value}</span>}
			</a>)
		}
		if(params.column.colId == "ticket_id"){
			return (
				<div style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px'
				}}>
					<div
						style={{
							height: '10px',
							backgroundColor: params?.data?.has_unread_message ? 'red' : 'transparent',
							width: '10px',
							borderRadius: '50%'
						}}
					/>
					<span>{params.value}</span>
				</div>
			)
		}
	  return <span>{params.value}</span>
	}

	const generateHeaderLabel = (header) => {
		if (header == 'accessory_category_id') {
			return 'Accessory Category Name'
		} else if (header == 'manufacturer_id') {
			return 'Manufacturer Name'
		} else if(header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(() => {
		gridRef.current.api.setQuickFilter(query_data);
	}, [query_data])

	useEffect(() => {
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	}, [props.serviceRequests])

	const handleChildClick = (value) => {
		setTabValue(value)
	};

	useEffect(() => {
		(tabValue == 1) && getLogs()
	}, [tabValue])

	useEffect(() => {
		setTabValue(tab_value)
	}, [tab_value])

	function redirectToAssetProfile(event) {
		router.push(`/assets-list/${event.data.asset_id}`);
	}

	function handleHideColumnDropDownDesign(){
		return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
  }


	useEffect( () => {
		if(quick_added_ticket){
		  props.fetchServiceReq()
		  dispatch(quickAddedTicket())
		}
	  }, [quick_added_ticket])

	return (
		<>
			{(tabValue == 0) && <AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName={'service_request'}
				logs={logs}
				displayTableName={'Service Tickets'}
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicket}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListing module={'service_request'} logs={logs} displayTableName={'Service Tickets'} />)}

			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '11px'
					}}
				>
					{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection={true}
						onGridReady={onGridReady}
						animateRows={true}
						rowData={props.serviceRequests}
						rowHeight={35}
						onRowClicked={() => {
						}}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}
						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}

						// status bar enabled on range selection
						enableRangeSelection={true}
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agAggregationComponent'
								},
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged={onRangeSelectionChanged}

						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged={onColumnRowGroupChanged}
						frameworkComponents={frameworkComponents}
						pagination={true}
					>
						<AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={100}
							minWidth={100}
							maxWidth={100}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											{props.viewServiceRequestPermission && <Tooltip id="view" title="View" placement="top">
												{/* <Icon onClick={() => handleClickOpen(data)} fontSize='small' style={{ color: '#1d2939', paddingBottom: '3px', paddingRight: '30px', fontSize: '15px', cursor: 'pointer' }} >visibility</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={() => handleClickOpen(data)} />
											</Tooltip>}
											{props.deleteServiceRequestPermission && <Tooltip id="delete" title="Archive" placement="top">
												{/* <Icon onClick={() => { handleArchive(data) }} style={{ color: '#1d2939', paddingBottom: '3px', paddingRight: '30px', fontSize: '15px', cursor: 'pointer' }}>archive</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-archive" onClick={() => handleArchive(data)} />
											</Tooltip>}
										</>
									)
								} else {
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
						{tableHeader.map(header => {
							if (header == 'status') {
								return (
									<AgGridColumn
									  field={header}
									  key={header}
									  minWidth={230}
									  cellEditorPopup
									  suppressSizeToFit
									  // cellEditor="agRichSelectCellEditor"
									  cellEditor='customDropdownEditor'
									  headerComponentFramework={CustomFilter}
									  sortable
									  
									  // floatingFilter
									  headerName={generateHeaderLabel(header)}
									  filter="text"
									  editable={false}
									  cellRendererFramework={(event) => {
										if (event.colDef.field === "status") {
										  return (
											<span
											  style={handleHideColumnDropDownDesign()}>
											  <span
												style={{
												  height: "10px",
												  width: "10px",
												  marginTop: "2px",
												  borderRadius: "50%",
												  display: "inline-block",
												  // backgroundColor: event.data?.asset_status_color?.code
												  backgroundColor:
													event.value?.split(",")[1] ??
													event.data?.status_details?.status_color?.code,
												}}
											  />
											  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
											  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
											  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
												{event.value?.split(",")[0]}
											  </span>
											</span>
										  );
										} if (
										  event.colDef.headerName == "Group" &&
										  event.node.field == "status"
										) {
										  return (
											<>
											  <span>{event.value}</span>
											</>
										  );
										}
										return (
										  <>
											<span>{event.value}</span>
										  </>
										);
					
									  }}
									/>
								  );
							}
							if (header == 'detailed_description_of_the_issue') {
								return (
									<AgGridColumn
										field={header}
										key={header}
										suppressSizeToFit={true}
										headerName={generateHeaderLabel(header)}
										sortable={true}
										minWidth={400}
										flex={1}
										headerComponentFramework={CustomFilter}
										filter="text"
									/>
								)
							}
							return (
								<AgGridColumn
									field={header}
									key={header}
									suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}
									sortable={true}
									flex={1}
									minWidth={200}
									cellRendererFramework={cellRendererFramework}
									headerComponentFramework={CustomFilter}
									filter="text"
								/>
							)
						})}
					</AgGridReact>
				</div>
			}

			{modalData && (
				<GeneralModal
					open={Boolean(modalData)}
					title={"Are you sure to archive service ticket?"}
					text={
						<h4>
							Are you sure to Archive this Service Ticket?
							{/* <b>{modalData.ticket_number}</b>? */}
						</h4>
					}
					detailMessage={"Your ticket will be moved to archive service tickets if you proceed"}
					handleClose={handleClose}
					handleConfirm={handleConfirm}
					actionButtonName="Yes, Proceed!"
				/>
			)}
		</>
	);
}

export default ListView