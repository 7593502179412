import useToast from '@fuse/hooks/useToast'
import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Modal, Box, Stepper, Step, StepButton, Button, Icon, IconButton,
	TextField, Typography, Drawer, Grid, CircularProgress, Switch, Autocomplete 
} from '@mui/material'
import Editor from '@fuse/components/Editor';
import { useCurrentUser, useDebounce } from '@fuse/hooks'
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from 'helper/alertModal'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import { acceptFileType } from 'helper/common'
import { downloadFile } from '@fuse/utils/fileUtils'
import { quickAddedTicket } from '../store/reportViewDialogReducer'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: '"Inter", sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > span': {
				margin: '10px auto'
			},
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px',
			marginRight: '20px',
			marginBottom: '20px',
			padding: '10px',
			backgroundColor: '#F9FAFB',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
			width: 520,
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			overflowX: 'hidden',
			overflowY: 'hidden'
		},
		helperText: {
			margin : 0,
			width: '100%',
			fontSize: '13px',
			color: '#FF0000',
			fontFamily: '"Inter", sans-serif !important',
			letterSpacing: '0.03333rem !important',
			lineHeight: '2.66 !important'
		}
	}
))

function AddServiceRequestFormDialog(props) {
	const steps = ['My Info', 'Asset Info', 'Issue Details'];
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const user = useCurrentUser();

	const [openAlertModal, setOpenAlertModal] = useState(false);
	const [loading, setLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [uploadingFile, setUploadingFile] = useState(false)
	const [fileName, setFileName] = useState('')
	const [fileLocation, setFileLocation] = useState('')
	const [previewFileLink, setPreviewFileLink] = useState('')
	const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false)
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [ serialNoSuggestions, setSerialNumberSuggestions ] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [ hideDetails, setHideDetails ] = useState(false);
	const [ selectedAsset, setSelectedAsset ] = useState(null);
	const [filePreviewLink, setFilePreviewLink] = useState('')
	const [openFilePreviewLink, setOpenFilePreviewLink] = useState(false)
	const [ checked, setChecked ] = useState(false)
	const [usersSuggestionsForCC, setUsersSuggestionsForCC ] = useState([])
	const [ selectedUserForCC, setSelectedUserForCC ] = useState([]);
	const defaultValues = {
		//My Info
		userName: '',
		userEmail: '',
		userPhoneNumber: '',

		//Asset Info
		assetDetails: null,

		//Issue Details
		subjectLine: '',
		description: ''
	};

	const validationSchema = [
		yup.object({
			// userName: yup.string().trim().required("Name is required"),
			// userEmail: yup.string().trim().required("Email Address is required"),
			// userPhoneNumber: yup.string().trim().required("Phone Number is required")
		}),

		yup.object({
			assetDetails: yup.object({
				id: yup.number(),
				label: yup.string(),
				asset_name: yup.string(),
				model_name: yup.string(),
				model_number: yup.string(),
				location: yup.string(),
				connection_status: yup.string()
			})
			.when({
				is: () => checked,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
		}),

		yup.object({
			subjectLine: yup.string()
				.trim()
				.max(30, "Subject line must be at most 30 characters!")
				.required("Subject Line is required!"),
			description: yup.string()
				.trim()
				.required()
		})
	]

	const currentValidationSchema = validationSchema[activeStep];

	const { 
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
		clearErrors,
		getValues,
		trigger
	} = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(currentValidationSchema),
		mode: "onChange"
	});

	//Editor onChange Event
	function onEditorContentChange(content) {
		setValue('description', content)
		content === '' ? trigger('description') : clearErrors("description")
	}

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	function handleClose() {
		props.close('ticket')
		reset()
		setFileName('')
		setFileLocation('')
	}

	const handleNext = async () => {
		await trigger();
		switch (activeStep) {
			// Required Details
			case 0:

			case 1:
				if(Object.keys(errors).length) return
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			default:
				break;
		}
	};

	const handleAlertModal = (_, reason) => {
		if (reason === 'backdropClick') {
			return;
		}
		setOpenAlertModal(!openAlertModal)
	}

	const handleCloseAlertModal = () => {
		setOpenAlertModal(false)
		handleClose()
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	function handleHasSerialNumberCheckBox( event ){
		setChecked(event.target.checked)
		if(!event.target.checked){
			setSelectedAsset(null)
			setValue('assetDetails', null)
			clearErrors('assetDetails')
		}
	}

	const onSubmit = async (data) => {
		const payload = {
			asset_id: data.assetDetails?.id ?? null,
			title: data.subjectLine,
			description: data.description,
			additional_cc_users: selectedUserForCC
			// 		req_file: fileName,
    	//   req_file_location: fileLocation
		}
		let result
		setIsLoading(true)
		await axios.post(`/service/request/submit`, payload, {
		}).then(results=>{
			result = results.data
			if (selectedFiles.length === 0){
				toast.success(result.message)
				dispatch(quickAddedTicket())
				handleClose()
			}

		}).catch(err=>{
			toast.error(err.response.data.message);
		}).finally(() => {
			setIsLoading(false)
		});

		if (selectedFiles.length > 0 && result.statusCode === 200) {
			setUploadingFile(true)
			const formData = new FormData();
			for (var i = 0; i < selectedFiles.length; i++) {
			  formData.append('files', selectedFiles[i]);
			}
			formData.append('service_request_id', result.data.id);
		await axios.post('/service/service-request-file-upload', formData).then(response => {
			toast.success(result.message)
			handleClose()
			dispatch(quickAddedTicket())
		}).catch(err => {
			console.log('136=>', err);
			toast.error(err.response.data.message);
		}).finally(() => {
			setUploadingFile(false)
		})
	}
	}

	const handleOnInputChange = useDebounce(( event ) => {
		if(!event?.target.value) return
		fetchSerialNumberSuggestions(event.target.value)
	},200);

	const fetchSerialNumberSuggestions = (value='') => {
		axios.get(`/service/assets/get-suggestions?search=${value}`).then( res => {
			const { data } = res.data;
			const uniqueItem = {}, suggestions = []
			for( let item of data ){
				if(!uniqueItem[item.serial_number]){
					uniqueItem[item.serial_number] = true
					suggestions.push({
						id: item.id,
						label: item.serial_number,
						asset_name: item.asset_name,
						model_name: item.asset_model.model_name,
						model_number: item.asset_model.model_no,
						location: `${item.shipping?.location_name}-${item.shipping.address1},
							${item.shipping?.city}, ${item.shipping.state}, ${item.shipping.country}`,
						connection_status: item.connection_status
					})
				}
			}
			setSerialNumberSuggestions(suggestions)
		}).catch(err => {
			console.log(err)
		})
  }

	const fetchUserSuggestionsForAdditionalCC = () => {
		axios.get(`/assets/fetch-users-suggestion`).then(response => {
			const { users } = response.data.data;
			setUsersSuggestionsForCC((users.filter(i => i.email !== user.data.email)).map(i =>i.email))
		}).catch(err => {
			console.log('164=>',err);
		})
	}

	useEffect(() => {
		setValue("userName", user.data.displayName)
		setValue("userEmail", user.data.email)
		setValue("userPhoneNumber", user.data.phone || '')

		if(props?.asset){
			setSelectedAsset({
				asset_name: props?.asset.asset_name,
				model_name: props.asset?.asset_model.model_name,
				model_number: props.asset?.asset_model.model_no,
				connection_status: props.asset.connection_status,
				location: `${props.asset.shipping?.location_name}-${props.asset.shipping.address1},
							${props.asset.shipping?.city}, ${props.asset.shipping.state}, ${props.asset.shipping.country}`,
			})
			setValue("assetDetails", {
				id: props?.asset.id,
				label: props?.asset.serial_number,
				asset_name: props?.asset.asset_name,
				model_name: props.asset?.asset_model.model_name,
				model_number: props.asset?.asset_model.model_no,
				connection_status: props.asset.connection_status,
				location: `${props.asset.shipping?.location_name}-${props.asset.shipping.address1},
							${props.asset.shipping?.city}, ${props.asset.shipping.state}, ${props.asset.shipping.country}`,
			})
		} else{
			fetchSerialNumberSuggestions()
		}
	}, [])

	useEffect(() => {
		
		const handleBeforeUnload = (event) => {
		  getValues('assetDetails') && event.preventDefault();
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		fetchUserSuggestionsForAdditionalCC()
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const handelChange = (event) => {
		if (selectedFiles.length >= 10) {
			return toast.error("You cannot add more than 10 file.");
		}
		const fileExtension = event.target.files[0] ? `.${event.target.files[0].name.split('.').pop().toLowerCase()}` : '';
		if (event.target.files[0] && !acceptFileType.includes(fileExtension)) {
		  return toast.error(`${fileExtension} files are not allowed!`)
		}
		if (event.target.files[0].size > 2000000) {
			return toast.error("File size cannot exceed 2Mb!");
		}
		if (event.target.files[0]) {
		  setSelectedFiles((prev) => [...prev, event.target.files[0]])
		}
	}
	const removeFile = (file) => {
		setSelectedFiles(selectedFiles.filter(function (item) {
			return item.name !== file.name
		}))
	}

	return (
		<div>
			<Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>Create New Ticket</h4>
					<IconButton onClick={handleAlertModal} style={{ padding: '0px' }}>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>

				<div className='progressbar_holder'>
					<div style={{ width: activeStep === 0 ? '33.33%' : activeStep === 1 ? '66.67%' : activeStep === 2 ? '100%' : '0%' }} className='progress'>
						&nbsp;
					</div>
				</div>

				<div className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<Stepper activeStep={activeStep} style={{marginBottom: '28px'}} className='stepper_holder'>
							{steps.map((label, index) => (
								<Step className='each_step_holder' key={label} completed={completed[index]}>
									<StepButton color="inherit" onClick={handleStep(index)}>
										{label}
									</StepButton>
								</Step>
							))}
						</Stepper>
						<div className='main_content_holder'>
							<>
								{/* <h4 className='main_heading'>
									{<span>{steps[activeStep]}</span>}
								</h4> */}
								<div className='content_holder'>
									{activeStep === 0 &&
										<div className='box_holder'>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Name
																<span className='required_span'>*</span>
															</Typography>
															<Controller 
																name="userName"
																className='serial_numberField'
																control={control}
																render={({ field }) => (
																	<TextField
																		{...field}
																		variant='outlined'
																		fullWidth
																		disabled
																		style={{ background: 'white' }}
																	/>
																)}
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Email Address
																<span className='required_span'>*</span>
															</Typography>
															<Controller
																name="userEmail"
																className='serial_numberField'
																control={control}
																render={({ field }) => (
																	<TextField
																		{...field}
																		variant='outlined'
																		fullWidth
																		disabled
																		style={{ background: 'white' }}
																	/>
																)}
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Phone Number
															</Typography>
															<Controller 
																name="userPhoneNumber"
																className='serial_numberField'
																control={control}
																render={({ field }) => (
																	<TextField
																		{...field}
																		variant='outlined'
																		fullWidth
																		disabled
																		style={{ background: 'white' }}
																	/>
																)}
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Additional cc</Typography>
															<Autocomplete
																style={{ width: '100%' }}
																options={usersSuggestionsForCC}
																value={selectedUserForCC}
																onChange={(event, value) => {
																	if(value.length > 10){
																		toast.error("Cannot add more than 10 users")
																		return
																	}
																	setSelectedUserForCC(value)
																}}
																multiple																				
																getOptionLabel={option => option ?? ''}
																renderInput={params => {
																		return (
																			<div ref={params.InputProps.ref}>
																				<TextField
																					required
																					{...params}
																					variant='outlined'
																					fullWidth
																					FormHelperTextProps={{
																						style: {
																							margin: 0,
																							backgroundColor: '#f4f4f4',
																							width: '100%',
																							paddingTop: '2px',
																							fontSize: '10px'
																						}
																					}}
																					style={{ width: '100%', backgroundColor: '#fff' }}
																				/>
																			</div>
																		)
																}}
															/> 																
														</Box>
													</Grid>
												</Grid>
											</Box>
										</div>
									}
									{activeStep === 1 &&
										<div className='box_holder'>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
																<Typography className='status_text'>
																	I have a Serial Number
																</Typography>
																<Switch
																	color="warning"
																	checked={props.disableHaveAssetSwitch ? true : checked}
																	onChange={handleHasSerialNumberCheckBox}
																	disabled={props.disableHaveAssetSwitch || false}
																/>
															</div>
															{ (props.disableHaveAssetSwitch ? true : checked) && (
																<>
																	<Typography className='status_text'>
																		Serial Number
																		<span className='required_span'>*</span>
																	</Typography>
																	<Controller
																		name="assetDetails"
																		className="mb-16"
																		control={control}
																		render={({ field: { ref, ...field }, fieldState: { error } }) => (
																			<Autocomplete
																				{...field} 
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={serialNoSuggestions}
																				disabled={props?.asset}
																				onChange={(_, value) => {
																					field.onChange(value)
																					setSelectedAsset(value)
																				}}
																				getOptionLabel={option => option.label ?? ''}
																				onInputChange={handleOnInputChange}
																				isOptionEqualToValue={(option, value) => option.id === value.id}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="assetDetails"
																								style={{background: 'white'}}
																								{...params}
																								placeholder='Type your serial number here to help us find your asset'
																								error={!!errors.assetDetails}
																								helperText={errors?.assetDetails?.message && 'Asset is required!'}
																								FormHelperTextProps={{
																									style: { 
																										margin : 0, 
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		)}
																	/>
																</>)
															}
														</Box>
													</Grid>
													{
														selectedAsset && (
															<>
																<Grid item xs={12}>
																	<Box>
																		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
																			<Typography className='status_text'>Asset Name</Typography>
																			<Button className='link_type_btn' onClick={() => setHideDetails(!hideDetails)}>
																				<span> {!hideDetails ? 'Hide' : 'Show'} Details</span>
																			</Button>
																		</div>
																		<div 
																			style={{
																				margin: 0,
																				padding: '8px 16px',
																				borderRadius: '4px',
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				border: '1px solid #12B76A',
																				fontFamily: 'Inter, sans-serif',
																				fontSize: '14px'
																			}}
																		>
																			{selectedAsset?.asset_name ?? ''}
																		</div>
																	</Box>
																</Grid>
																{!hideDetails &&
																<>
																	<Grid item xs={6}>
																		<Box>
																			<Typography style={{color:"#667085"}} className='status_text'>Model Name</Typography>
																			<Typography 
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																					fontFamily: 'Inter, sans-serif',
																					fontWeight:500,
																					fontSize:'14px',
																					color:'#1D2939'
																				}} variant="subtitle1"
																			>
																				<span>{selectedAsset?.model_name ?? 'N/A'}</span>
																				<span className='orange_badge_holder ml-3'>{selectedAsset?.connection_status ?? 'N/A'}</span>
																			</Typography>
																		</Box>
																	</Grid>
																	<Grid item xs={6}>
																		<Box>
																			<Typography style={{color:"#667085"}} className='status_text'>Model Number</Typography>
																			<Typography
																				style={{
																					fontFamily: 'Inter, sans-serif',
																					fontWeight:500,
																					fontSize:'14px',
																					color:'#1D2939'
																				}} 
																				variant="subtitle1"
																			>{selectedAsset?.model_number ?? 'N/A'}</Typography>
																		</Box>
																	</Grid>
																	<Grid item xs={12}>
																		<Box>
																			<Typography style={{color:"#667085"}} className='status_text'>Location</Typography>
																			<Typography
																				style={{
																					fontFamily: 'Inter, sans-serif',
																					fontWeight:500,
																					fontSize:'14px',
																					color:'#1D2939'
																				}} variant="subtitle1"
																			>{selectedAsset.location ?? 'N/A'}</Typography>
																		</Box>
																	</Grid>
																</>
																}
															</>
														)
													}
												</Grid>
											</Box>
										</div>
									}
									{activeStep === 2 &&
										<>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Subject Line
																<span className='required_span'>*</span>
															</Typography>
															<Controller 
																name="subjectLine"
																className='serial_numberField'
																control={control}
																render={({ field }) => (
																	<TextField
																		{...field}
																		variant='outlined'
																		fullWidth
																		style={{ background: 'white' }}
																		placeholder='e.g Access Point is offline'
																		error={!!errors.subjectLine}
																		helperText={errors?.subjectLine?.message}
																		FormHelperTextProps={{
																			style: { 
																				margin : 0, 
																				backgroundColor: '#f4f4f4',
																				width: '100%',
																				paddingTop: '2px',
																				fontSize: '10px',
																				color: '#FF0000'
																			}
																		}}
																	/>
																)}
															/>
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Description
																<span className='required_span'>*</span>
															</Typography>
															<Controller 
																name="description"
																className='serial_numberField'
																control={control}
																render={({ field }) => (
																	<Editor
																		editorContent={getValues('description')}
																		onEditorContentChange={onEditorContentChange}
																		placeholderText= "Tell us a bit more about the issue that can help us in investigating this"
																	/>
																)}
															/>
															{errors?.description && <span className={classes.helperText}>Description is required!</span>}
														</Box>								
													</Grid>
													<Grid item xs={12}>
														{/* Image upload */}
														<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
															<Typography className='status_text'>Upload Files</Typography>
															<div className='upload_imgBox flex-wrap relative'>
																<label htmlFor="upload-photo" style={{ cursor: 'pointer', maxWidth: '200px' }}>
																	<span style={{ color: '#667085' }} className='icon_holder'>
																		<Icon>
																			photo_camera
																		</Icon>
																		<span className='file_info_content'><span>Upload files</span><br/>Supports only gif, png, jpg, jpeg, doc, docx, pdf, txt, xls, xlsx, csv, ppt, pptx Max size: 2Mb (rec. 700x430px)</span>
																	</span>
																</label>
																<input type="file"   
																onChange={(e) => handelChange(e)}
 																name="photo" id="upload-photo" />
															
															</div>
														</div>
														<div  style={{ marginLeft: '10px', marginTop: '2px' }} className='w-full'>
														{loading && <CircularProgress />}
															{
															selectedFiles.map((file, key) => (
																<>
																<div className='singleAttachment mt-1' key={key}>
																	<span className='me-3 fs-6 fw-semibold text-truncate'
																	onClick={() => {
																		if (file.name == '' || file.file_name == 'No File Name') return;
																		const fileExtension = file.name ? file.name.split('.').pop().toLocaleLowerCase() : file.file_name.split('.').pop().toLocaleLowerCase()
																		const imageFileExtension = ['png','jpeg','jpg']
																		if(imageFileExtension.includes(fileExtension)){
																			setOpenFilePreviewLink(true)
																			setFilePreviewLink(URL.createObjectURL(file))
																			setFileName(file.name ?? file?.file_name)
																		}else{
																		downloadFile(URL.createObjectURL(file),file.name ?? file?.file_name)
																		}

																	}}
																	 style={{
																		width: '150px', display: 'inline-block' , cursor: 'pointer'
																	}}>{file?.name ?? file?.file_name} </span>

																	<i className='ti ti-xbox-x' onClick={(e) => {
																		 removeFile(file)
																		}}>
																	</i>
																	
																</div >
																</>
															))
															}
														</div>
													</Grid>
												</Grid>
											</Box>
										</>
									}
								</div>
								<div className='btn_holder'>
									{activeStep !== 0 &&
										<Button
											color="inherit"
											disabled={activeStep === 0}
											onClick={handleBack}
											className='back_btn'
										>
											Back
										</Button>
									}

									{activeStep !== steps.length - 1 &&
										<Button onClick={handleNext} className='next_btn'>
											Next
										</Button>
									}
									{activeStep === steps.length - 1 &&
										<Button onClick={handleSubmit(onSubmit)} disabled={isLoading || uploadingFile} className='next_btn' style={{cursor: (isLoading || uploadingFile) ? 'not-allowed' : 'pointer'}}>
											Proceed
										</Button>
									}
								</div>
							</>
						</div>
					</Box>

				</div>
			</Drawer>

			{openAlertModal &&
				<AlertModal
					openAlerModal={openAlertModal}
					module="service Ticket"
					action={'adding'}
					handleCloseAlertModal={handleCloseAlertModal}
					handleAlertModal={handleAlertModal} 
				/>
			}

{
				openFilePreviewLink && <FilePreviewModal
					open={openFilePreviewLink}
					setOpen={setOpenFilePreviewLink}
					filePreviewLink={filePreviewLink}
					fileName={fileName}
				/>
			}
		</div >
	)
}
export default AddServiceRequestFormDialog

function FilePreviewModal ({ open, setOpen, filePreviewLink, fileName }) {
	const classes = useStyles()
	const fileExtension = fileName.split('.').pop().toLocaleLowerCase()
	const imageFileExtension = ['png','jpeg','jpg']
	let isImageFile = false
	if(imageFileExtension.includes(fileExtension)){
		isImageFile = true
	}

	return (
		<Modal
			open={ open }
			className={classes.modal}
		>
			<div style={{
				height: '60vh',
				width: '50vw',
				backgroundColor: 'white',
				display: 'flex',
				justifyContent: 'center',
				alignItems:'center',
				borderRadius: '13px',
				flexDirection: 'column',
				position:'relative',
				padding:'30px'
			}}>
				<IconButton 
					style={{position:'absolute',right: '0px',top: '0px'}} 
					onClick={()=>{
						setOpen(false)
					}}>
					<Icon>close</Icon>
				</IconButton>
				{
					isImageFile
					?
					<img style={{ height: '400px', width: '100%' }} src={filePreviewLink || ''} alt=''/>
					:
					<iframe style={{height: '400px',width: '100%'}} src={`https://docs.google.com/viewer?url=${encodeURIComponent(filePreviewLink)}&embedded=true`} />
				}
			</div>
		</Modal>
	)
}